<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { AUDIT_ENTITIES } from '@domains/Shared/constants/audit.const';
import { MuiTable } from '@emobg/vue-base';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import { contentCells } from './invoiceAuditPaymentsContentCells';

export default {
  name: 'InvoiceAuditPaymentsView',

  components: {
    MuiTable,
  },

  inject: ['$labels'],

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.shared.audit, {
      paymentAuditData: state => state.data,
      isPaymentAuditDataLoading: state => state.isLoading,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, {
      operatorFilters: 'getOperatorFilter',
    }),
  },

  async created() {
    this.contentCells = contentCells;

    const invoiceUuid = get(this.$route, 'params.invoiceUuid');
    const invoiceId = await this.getInvoiceId(invoiceUuid);

    await this.getAuditData({
      primaryEntity: AUDIT_ENTITIES.paymentTransaction,
      secondaryEntity: AUDIT_ENTITIES.invoice,
      secondaryId: invoiceId,
    });
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.shared.audit, [
      'getAuditData',
    ]),
    async getInvoiceId(invoiceUuid) {
      const results = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.invoices, {
        filters: `cs_operator_fk:${this.activeOperatorId} AND uuid:${invoiceUuid}`,
        attributesToRetrieve: ['id'],
      });

      return get(results, 'hits[0].id');
    },
  },
};
</script>

<template>
  <div class="InvoiceAuditInvoicesView">
    <h2>
      {{ $labels.Audit.payments.title }}
    </h2>

    <ui-card
      rounded
      class="d-block py-3 mt-2"
    >
      <MuiTable
        :data-set="paymentAuditData"
        :content-cells="contentCells"
        :is-loading="isPaymentAuditDataLoading"
        :no-data-label="FALLBACK_MESSAGE.dash"
        data-test-id="isPaymentAuditDataLoading"
        class="mx-0"
      />
    </ui-card>
  </div>
</template>
