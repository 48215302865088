import invoicingRoutes from '@domains/Invoicing/router/InvoicingRouterMap';
import { INVOICING_PERMISSIONS } from './permissions';

export const INVOICE_TABS = [
  {
    label: {
      title: 'Details',
    },
    url: invoicingRoutes.invoices.invoice.details,
    permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
  },
  {
    label: {
      title: 'Related',
    },
    url: invoicingRoutes.invoices.invoice.related,
    permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
  },
  {
    label: {
      title: 'Send',
    },
    url: invoicingRoutes.invoices.invoice.send,
    permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
  },
  {
    label: {
      title: 'Audit',
    },
    url: invoicingRoutes.invoices.invoice.audit.index,
    permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
  },
];

export const INVOICE_AUDIT_TABS = [
  {
    label: {
      title: 'Invoices',
    },
    url: invoicingRoutes.invoices.invoice.audit.invoices,
    permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
  },
  {
    label: {
      title: 'Invoice lines',
    },
    url: invoicingRoutes.invoices.invoice.audit.lines,
    permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
  },
  {
    label: {
      title: 'Payment transactions',
    },
    url: invoicingRoutes.invoices.invoice.audit.payments,
    permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
  },
];
