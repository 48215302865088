<script>
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import { MuiTable } from '@emobg/vue-base';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { AUDIT_ENTITIES } from '@domains/Shared/constants/audit.const';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import contentCells from './invoiceAuditLinesContentCells';

export default {
  name: 'InvoiceAuditLinesView',

  components: {
    MuiTable,
  },

  inject: ['$labels'],

  data() {
    return {
      auditFilter: null,
      services: {},
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.shared.audit, {
      invoiceLineAuditData: state => state.data,
      isInvoiceLineDataLoading: state => state.isLoading,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, {
      operatorFilters: 'getOperatorFilter',
    }),
  },

  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;

    const invoiceUuid = get(this.$route, 'params.invoiceUuid');
    const invoiceId = await this.getInvoiceId(invoiceUuid);

    await this.getAuditData({
      primaryEntity: AUDIT_ENTITIES.invoiceLine,
      secondaryEntity: AUDIT_ENTITIES.invoice,
      secondaryId: invoiceId,
    });

    this.loadServices();
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.shared.audit, [
      'getAuditData',
    ]),
    async getInvoiceId(invoiceUuid) {
      const results = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.invoices, {
        filters: `cs_operator_fk:${this.activeOperatorId} AND uuid:${invoiceUuid}`,
        attributesToRetrieve: ['id'],
      });

      return get(results, 'hits[0].id');
    },

    async loadServices() {
      const services = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.services, {
        filters: `cs_operator_fk:${this.activeOperatorId}`,
        hitsPerPage: 200,
      });

      this.services = reduce(services.hits, (serviceMap, { id, name }) => ({
        ...serviceMap,
        [id]: name,
      }), {});
    },
  },
};
</script>

<template>
  <div
    class="InvoiceAuditInvoicesView"
    data-test-id="invoice_audit_invoices_lines-view"
  >
    <h2>
      {{ $labels.Audit.lines.title }}
    </h2>

    <ui-card
      rounded
      class="d-block py-3 mt-2"
    >
      <MuiTable
        data-test-id="invoice_lines-list"
        :data-set="invoiceLineAuditData"
        :content-cells="contentCells(services)"
        :is-loading="isInvoiceLineDataLoading"
        :no-data-label="FALLBACK_MESSAGE.dash"
        class="mx-0"
      />
    </ui-card>
  </div>
</template>
