<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import find from 'lodash/find';
import head from 'lodash/head';
import { mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { INVOICE_AUDIT_TABS } from '@domains/Invoicing/const/InvoiceTabs';
import { PageTabs, PageView } from '@/components';

export default {
  name: 'InvoiceAuditView',

  components: {
    PageTabs,
    PageView,
  },

  data() {
    return {
      allowedTabs: [],
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
  },

  mounted() {
    this.allowedTabs = filter(INVOICE_AUDIT_TABS, item => intersection(this.userPermissions, item.permissions).length);

    const selectedTab = find(this.allowedTabs, ['url', this.$route.name]);

    if (!selectedTab) {
      const firstTab = get(head(this.allowedTabs), 'url');
      this.$router.push({ name: firstTab });
    }
  },
};
</script>

<template>
  <div
    class="InvoiceAuditView"
    data-test-id="invoice_audit-view"
  >
    <PageTabs
      :tabs="allowedTabs"
      are-inner-tabs
    />
    <PageView is-inner-tab-content>
      <RouterView />
    </PageView>
  </div>
</template>

