import {
  DATE_FORMAT,
  formatPreciseCurrency,
  formatUtc,
  titleCase,
} from '@emobg/web-utils';
import toString from 'lodash/toString';

export default function contentCells(services) {
  return [
    {
      property: 'message',
      title: 'Action',
      width: 180,
    },
    {
      property: 'userName',
      title: 'User',
      width: 140,
      transformValue: titleCase,
    },
    {
      property: 'createdAtTs',
      title: 'Date and Time (UTC)',
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended),
      width: 140,
    },
    {
      property: 'valueAfter.serviceFk',
      title: 'Service',
      transformValue: serviceId => services[serviceId],
      width: 200,
    },
    {
      property: 'valueAfter.units',
      title: 'Units',
      width: 30,
      transformValue: toString,
    },
    {
      property: 'valueAfter',
      title: 'Amount',
      transformValue: value => formatPreciseCurrency(value.lineGrossAmount, value.currency),
      width: 70,
    },
  ];
}
