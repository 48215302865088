var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "InvoiceAuditInvoicesView",
      attrs: { "data-test-id": "invoice_audit_invoices_lines-view" },
    },
    [
      _c("h2", [_vm._v(" " + _vm._s(_vm.$labels.Audit.lines.title) + " ")]),
      _c(
        "ui-card",
        { staticClass: "d-block py-3 mt-2", attrs: { rounded: "" } },
        [
          _c("MuiTable", {
            staticClass: "mx-0",
            attrs: {
              "data-test-id": "invoice_lines-list",
              "data-set": _vm.invoiceLineAuditData,
              "content-cells": _vm.contentCells(_vm.services),
              "is-loading": _vm.isInvoiceLineDataLoading,
              "no-data-label": _vm.FALLBACK_MESSAGE.dash,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }