import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatPreciseCurrency,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { INVOICE_PAYMENT_STATUS } from '@domains/Invoicing/const/INVOICE_PAYMENT_STATUS';
import { COLORS } from '@emobg/vue-base';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const PAYMENT_STATUS_COLORS = {
  [INVOICE_PAYMENT_STATUS.success]: COLORS.success,
  [INVOICE_PAYMENT_STATUS.pending]: COLORS.warning,
  [INVOICE_PAYMENT_STATUS.failed]: COLORS.danger,
};

export const contentCells = [
  {
    property: 'message',
    title: 'Action',
    width: 180,
  },
  {
    property: 'createdAtTs',
    title: 'Date and Time (UTC)',
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended),
    width: 150,
  },
  {
    title: 'User',
    property: 'userName',
    width: 200,
  },
  {
    property: 'valueAfter',
    title: 'Amount',
    transformValue: value => formatPreciseCurrency(value.amount, value.currency),
    width: 70,
  },
  {
    property: 'status',
    title: 'Status',
    width: 80,
    component: BadgeComponent,
    props: value => ({
      text: sentenceCase(value.valueAfter.status),
      color: PAYMENT_STATUS_COLORS[value.valueAfter.status],
    }),
  },
  {
    property: 'valueAfter.providerStatusRaw',
    title: 'Adyen status',
    transformValue: value => sentenceCase(value || FALLBACK_MESSAGE.dash),
    width: 70,
  },
];
