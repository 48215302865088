var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InvoiceAuditInvoicesView" },
    [
      _c("h2", [_vm._v(" " + _vm._s(_vm.$labels.Audit.payments.title) + " ")]),
      _c(
        "ui-card",
        { staticClass: "d-block py-3 mt-2", attrs: { rounded: "" } },
        [
          _c("MuiTable", {
            staticClass: "mx-0",
            attrs: {
              "data-set": _vm.paymentAuditData,
              "content-cells": _vm.contentCells,
              "is-loading": _vm.isPaymentAuditDataLoading,
              "no-data-label": _vm.FALLBACK_MESSAGE.dash,
              "data-test-id": "isPaymentAuditDataLoading",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }